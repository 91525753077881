<template>
  <div class="coverage-requests">
    <h2 class="aon-peacock--text pt-7 pb-6">
      {{ $t('coverageRequests.pageTitle') }}
    </h2>
    <v-row>
      <v-col cols="3">
        <TableFiltersSelect
          :active-filters="activeFilters"
          :filter-types="filterTypes"
          bottom-chips
          @change="getCoverageRequests"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet elevation="0" class="elevation-1">
          <v-data-table
            :headers="coverageRequestsHeaders"
            :items="coverageRequests"
            :loading="loading"
            :options.sync="pagingOptions"
            hide-default-header
            hide-default-footer
          >
            <template #header="{ props: { headers, options }, on: { sort } }">
              <table-header
                :headers="headers"
                :options="options"
                @sort="sort"
              />
            </template>

            <template #item.type="{ item }">
              {{ $t(`mappings.insurance.${item.type}`) }}
            </template>

            <template #item.requestedCoverageAmount="{ item }">
              {{ formatCoverage(item.requestedCoverageAmount) }}
            </template>

            <template #item.requestedPerOccurrenceCoverageAmount="{ item }">
              {{ formatCoverage(item.requestedPerOccurrenceCoverageAmount) }}
            </template>

            <template #item.updated="{ item }">
              {{ item.updated | longDate }}
            </template>

            <template #item.status="{ item }">
              <div class="d-flex align-center justify-space-between">
                <v-chip class="status ml-2" :class="chipClass(item.status)">
                  {{ contextStatus(item.status) }}
                </v-chip>
                <v-menu offset-y left eager>
                  <template #activator="{ on }">
                    <AppButton class="ml-auto" variation="icon" v-on="on">
                      <i class="fal fa-ellipsis-h" />
                    </AppButton>
                  </template>
                  <v-list class="py-0">
                    <v-list-item link :to="getCoverageRequestRoute(item)">
                      <v-list-item-title class="aon-teal--text">
                        {{ $t(`coverageRequests.table.details`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template #footer="{ props: { pagination } }">
              <table-footer
                :pagination.sync="pagination"
                :options.sync="pagingOptions"
              />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableFiltersSelect from '@aon/cfs-components/src/components/DataTables/TableFiltersSelect';
import { insuranceTypeRouteMap } from '@/lib/mappings';
import { mapState } from 'vuex';
import TableHeader from '@aon/cfs-components/src/components/DataTables/TableHeader';
import TableFooter from '@aon/cfs-components/src/components/DataTables/TableFooter';

export default {
  name: 'CoverageRequests',
  components: {
    TableFiltersSelect,
    TableHeader,
    TableFooter,
  },
  data() {
    return {
      activeFilters: [],
      coverageRequestsHeaders: [
        {
          value: 'applicantName',
          text: this.$t('coverageRequests.table.applicant'),
        },
        {
          value: 'type',
          text: this.$t('coverageRequests.table.insuranceType'),
        },
        {
          value: 'requestedCoverageAmount',
          text: this.$t('coverageRequests.table.requestedCoverage'),
        },
        {
          value: 'requestedPerOccurrenceCoverageAmount',
          text: this.$t(
            'coverageRequests.table.requestedPerOccurrenceCoverageAmount'
          ),
        },
        {
          value: 'updated',
          text: this.$t('coverageRequests.table.updated'),
        },
        {
          value: 'status',
          text: this.$t('coverageRequests.table.status'),
        },
      ],
      filterTypes: [
        {
          typeText: this.$t('coverageRequests.table.status'),
          typeValue: 'status',
          values: [
            {
              text: this.$t('coverageRequest.status.GATHERING_INFO'),
              value: 'SUBMITTED',
            },
            {
              text: this.$t('coverageRequest.status.APPLICANT_REVIEW'),
              value: 'APPLICANT_REVIEW',
            },
            {
              text: this.$t('coverageRequest.status.OUT_TO_MARKET'),
              value: 'OUT_TO_MARKET',
            },
            {
              text: this.$t('coverageRequest.status.FINALIZE'),
              value: 'FINALIZE',
            },
            {
              text: this.$t('coverageRequest.status.COMPLETED'),
              value: 'COMPLETED',
            },
            {
              text: this.$t('coverageRequest.status.WITHDRAWN'),
              value: 'WITHDRAWN',
            },
          ],
        },
        {
          typeText: this.$t('coverageRequests.table.insuranceType'),
          typeValue: 'type',
          values: [
            {
              text: this.$t('mappings.insurance.ERRS_OMISNS'),
              value: 'ERRS_OMISNS',
            },
            {
              text: this.$t('mappings.insurance.IP'),
              value: 'IP',
            },
            {
              text: this.$t('mappings.insurance.CYBER'),
              value: 'CYBER',
            },
            {
              text: this.$t('mappings.insurance.GL'),
              value: 'GL',
            },
          ],
        },
      ],
      insuranceTypeRouteMap,
      loading: false,
      pagingOptions: {
        page: 1,
      },
    };
  },
  computed: {
    ...mapState('coverageRequests', {
      coverageRequests: state => state.coverageRequests,
    }),
  },
  watch: {
    '$route.query.status': function(status) {
      this.setActiveFilters(status);
    },
  },
  async mounted() {
    let filters = {};
    const status = this.$route.query.status;

    this.setActiveFilters(status);

    if (status) {
      filters['status'] = status;
    }

    // this.getCoverageRequests(filters);
  },
  methods: {
    chipClass(status) {
      return `${status.toLowerCase().replace(/_/g, '-')}-chip`;
    },
    async getCoverageRequests() {
      this.loading = true;

      // await this.$store.dispatch(
      //   'coverageRequests/getCoverageRequests',
      //   filters
      // );

      this.loading = false;
    },
    contextStatus(status) {
      const contextStatus = status === 'SUBMITTED' ? 'GATHERING_INFO' : status;

      return this.$t(`coverageRequest.status.${contextStatus}`);
    },
    getCoverageRequestRoute(application) {
      return {
        name: 'Coverage Request',
        params: {
          tenantId: application.tenantId,
          type: insuranceTypeRouteMap[application.type],
          id: application.id,
        },
      };
    },
    setActiveFilters(status) {
      if (!status) {
        this.activeFilters = [];

        return;
      }
      this.activeFilters = [
        {
          type: 'status',
          value: status,
        },
      ];
    },
    formatCoverage(coverage) {
      return coverage ? this.$options.filters.currency(coverage) : '--';
    },
  },
};
</script>
