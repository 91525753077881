var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coverage-requests"},[_c('h2',{staticClass:"aon-peacock--text pt-7 pb-6"},[_vm._v(" "+_vm._s(_vm.$t('coverageRequests.pageTitle'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('TableFiltersSelect',{attrs:{"active-filters":_vm.activeFilters,"filter-types":_vm.filterTypes,"bottom-chips":""},on:{"change":_vm.getCoverageRequests}})],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"elevation-1",attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.coverageRequestsHeaders,"items":_vm.coverageRequests,"loading":_vm.loading,"options":_vm.pagingOptions,"hide-default-header":"","hide-default-footer":""},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var ref_props = ref.props;
var headers = ref_props.headers;
var options = ref_props.options;
var sort = ref.on.sort;
return [_c('table-header',{attrs:{"headers":headers,"options":options},on:{"sort":sort}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("mappings.insurance." + (item.type))))+" ")]}},{key:"item.requestedCoverageAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCoverage(item.requestedCoverageAmount))+" ")]}},{key:"item.requestedPerOccurrenceCoverageAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCoverage(item.requestedPerOccurrenceCoverageAmount))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("longDate")(item.updated))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-chip',{staticClass:"status ml-2",class:_vm.chipClass(item.status)},[_vm._v(" "+_vm._s(_vm.contextStatus(item.status))+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('AppButton',_vm._g({staticClass:"ml-auto",attrs:{"variation":"icon"}},on),[_c('i',{staticClass:"fal fa-ellipsis-h"})])]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"link":"","to":_vm.getCoverageRequestRoute(item)}},[_c('v-list-item-title',{staticClass:"aon-teal--text"},[_vm._v(" "+_vm._s(_vm.$t("coverageRequests.table.details"))+" ")])],1)],1)],1)],1)]}},{key:"footer",fn:function(ref){
var pagination = ref.props.pagination;
return [_c('table-footer',{attrs:{"pagination":pagination,"options":_vm.pagingOptions},on:{"update:pagination":function($event){pagination=$event},"update:options":function($event){_vm.pagingOptions=$event}}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }